import Vue from "vue";
import store from "../store";
const placeholder = require("@/assets/imgs/placeholder2.png");

export default {
  si() {
    //storeInfo
    return store.state.storeInfo;
  },

  vi() {
    //vendorInfo
    return store.state.activeVendor;
  },

  sid() {
    return store.state.storeInfo._id;
  },

  storeS3(storeId) {
    return `https://${storeId}.s3.amazonaws.com/`;
  },

  getS3URL(storeId, data) {
    if (data && data.includes(storeId)) {
      const aws = store.state.meta.aws;
      return aws.cf + data;
    }
    return this.storeS3(storeId) + data;
  },

  logoURL() {
    const si = this.si();
    let path = si.webstore?.logo || si.appLogo;
    return path ? this.getS3URL(si._id, path) : "";
  },

  getAssetURL(data, storeId = "") {
    // let sid = storeId;
    // if (!storeId)
    //   sid = store.state.activeVendor._id
    //     ? store.state.activeVendor._id
    //     : store.state.storeInfo._id;

    // const s3 = `https://${sid}.s3.amazonaws.com/`;

    // if (!data) return placeholder;
    // const array = data.split(".");
    // if (
    //   data &&
    //   !array.includes(store.state.meta.growcifyMasterInventory) &&
    //   (array[1] === "png" || array[1] === "mp4")
    // )
    //   return s3 + data;
    // return data;
    if (!data) return placeholder;

    let sid = storeId;
    if (!storeId) {
      const av = store.state.activeVendor;
      sid = av._id ? av._id : this.sid();
    }

    const array = data.split(".");
    if (
      data &&
      !array.includes(store.state.meta.growcifyMasterInventory) &&
      (array[1] === "png" || array[1] === "mp4")
    ) {
      return this.getS3URL(sid, data);
    }

    return data;
  },

  getOriginalAssetURL(data, sid) {
    if (!data) return placeholder;
    data = data.replace("resized-", "");
    return this.getAssetURL(data, sid);
  },

  getPhotoURL(data) {
    if (!data) return placeholder;

    const array = data.split(".");
    if (
      data &&
      !array.includes(store.state.meta.growcifyMasterInventory) &&
      (array[1] === "png" || array[1] === "mp4")
    ) {
      return this.getS3URL(this.sid(), data);
    }

    return data;
  },

  getOriginalPhotoURL(data) {
    return data.replace("resized-", "");
  },

  verifyStore(
    hostname = window.location.hostname,
    storeId = false,
    ngProgress = true
  ) {
    if (storeId)
      return Vue.axios.get(`store/verify/${storeId}`, {
        ngProgress: ngProgress,
      });
    return Vue.axios.post(
      `store/verify`,
      { growcifyHostname: hostname },
      { ngProgress: ngProgress }
    );
  },

  getActiveCountries() {
    return Vue.axios.get(`country/list/active`, { ngProgress: false });
  },

  getCommonEnums() {
    return Vue.axios.get(`common-enums`, { ngProgress: false });
  },

  getStoreMiscSettings(_id) {
    return Vue.axios.get(`store/misc-settings/${_id}`, { ngProgress: false });
  },

  getPaymentSettings(_id) {
    return Vue.axios.get(`integration/list/payment/${_id}`, {
      ngProgress: false,
    });
  },

  getBankAccount(_id) {
    return Vue.axios.get(`bank-account/${_id}`, { ngProgress: false });
  },

  getCityByPincode(pincode) {
    return Vue.axios.get(`city/pincode/${pincode}`, { ngProgress: false });
  },

  searchPlaces(_id, keyword) {
    return Vue.axios.get(`place/${_id}?keyword=${keyword}`, {
      ngProgress: false,
    });
  },

  //coupons
  getCoupons(_id, type = "orders") {
    return Vue.axios.get(`coupon/list/${type}/${_id}/active`, {
      ngProgress: false,
    });
  },

  //banners
  getMainBanners(_id, ngProgress = true) {
    return Vue.axios.get(`banner/list/main/${_id}`, { ngProgress: ngProgress });
  },

  getMiniBanners(_id, ngProgress = true) {
    return Vue.axios.get(`banner/list/mini/${_id}`, { ngProgress: ngProgress });
  },

  //categories
  getCategories(_id, count = 0, ngProgress = true) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-parent" : "parent";
    return Vue.axios.get(
      `category/list/${this.sid()}/${mvquery}${count ? "/" + count : ""}`,
      { ngProgress: ngProgress }
    );
  },

  getCategoriesTree(_id, ngProgress = true) {
    return Vue.axios.get(`category/list/tree/${_id}`, {
      ngProgress: ngProgress,
    });
  },

  getSubCategories(_id, catId) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-sub-categories" : "sub-categories";
    return Vue.axios.get("category/list/" + mvquery + "/" + _id + "/" + catId);
  },

  getSiblingCategories(_id, catId) {
    return Vue.axios.get(
      "category/list/sibling-categories/" + _id + "/" + catId
    );
  },

  getCategoriesSubcategories(_id, ngProgress = true) {
    return Vue.axios.get("category/list/tree/" + _id, {
      ngProgress: ngProgress,
    });
  },

  getCategory(_id, slug, noProgress = true) {
    return Vue.axios.get(`category/${_id}/` + slug, { ngProgress: noProgress });
  },

  //products
  getRecentProducts(_id, ngProgress = true, count = 12) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-recent" : "recent";
    return Vue.axios.get(`product/list/${mvquery}/${_id}/${count}`, {
      ngProgress: ngProgress,
    });
  },

  getTrendingProducts(_id, ngProgress = true, count = 12) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-trending" : "trending";
    return Vue.axios.get(`product/list/${mvquery}/${_id}/${count}`, {
      ngProgress: ngProgress,
    });
  },

  getRecommendedProducts(_id, ngProgress = true, count = 12) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-recommended" : "recommended";
    return Vue.axios.get(`product/list/${mvquery}/${_id}/${count}`, {
      ngProgress: ngProgress,
    });
  },

  getFeaturedProducts(_id, ngProgress = true, count = 12) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-featured" : "featured";
    return Vue.axios.get(`product/list/${mvquery}/${_id}/${count}`, {
      ngProgress: ngProgress,
    });
  },

  getComingSoonProducts(_id, ngProgress = true, count = 12) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-coming-soon" : "coming-soon";
    return Vue.axios.get(`product/list/${mvquery}/${_id}/${count}`, {
      ngProgress: ngProgress,
    });
  },

  getProduct(_id, ngProgress = true) {
    return Vue.axios.get(`product/${_id}`, { ngProgress: ngProgress });
  },

  getProductBySlug(_id, slug, ngProgress = true) {
    return Vue.axios.get(`product/${_id}/${slug}`, { ngProgress: ngProgress });
  },

  getSimilarProducts(_id, productId, ngProgress = true) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-similar" : "similar";
    return Vue.axios.get(`product/list/${mvquery}/${_id}/${productId}`, {
      ngProgress: ngProgress,
    });
  },

  getProductsByIDs(_id, _ids, ngProgress = true) {
    return Vue.axios.post(
      `product/list/${_id}`,
      { _ids: _ids },
      { ngProgress: ngProgress }
    );
  },

  getProductsByCategory(
    _id,
    category,
    filter,
    pageNumber,
    pageSize,
    keyword,
    ngProgress = true
  ) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv/" : "";
    let endpoint = `product/list/${mvquery}${_id}/${category}/${pageNumber}/${pageSize}`;

    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.post(endpoint, filter, { ngProgress: ngProgress });
  },

  getAllProductsByMainCategory(
    _id,
    category,
    filters,
    pageNumber,
    pageSize,
    keyword,
    ngProgress = true
  ) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv-main" : "main";
    let endpoint = `product/list/${mvquery}/${_id}/${category}/${pageNumber}/${pageSize}`;

    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.post(endpoint, filters, { ngProgress: ngProgress });
  },

  getCategoryTree(_id, ids) {
    return Vue.axios.post(`product/cart/category/tree/${_id}`, ids, {
      ngProgress: false,
    });
  },

  search(_id, keyword) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? "mv/" : "";
    return Vue.axios.get(`search/${mvquery}${_id}?keyword=${keyword}`, {
      ngProgress: false,
    });
  },

  //brands
  getBrands(_id, ngProgress = false) {
    return Vue.axios.get(`brand/list/${_id}/active`, {
      ngProgress: ngProgress,
    });
  },

  //groups
  getGroups(_id, pageNumber, pageSize, ngProgress = false) {
    return Vue.axios.get(`group/list/${_id}/${pageNumber}/${pageSize}`, {
      ngProgress: ngProgress,
    });
  },

  getGroup(_id, groupId, ngProgress = false) {
    return Vue.axios.get(`group/${_id}/${groupId}`, { ngProgress: ngProgress });
  },

  getGroupCategories(_id, groupId, ngProgress = false) {
    return Vue.axios.get(`group/list/categories/${_id}/${groupId}`, {
      ngProgress: ngProgress,
    });
  },

  //collections
  getCollection(_id, slug, filters, pageNumber, pageSize, ngProgress = false) {
    return Vue.axios.post(
      `collection/list/${slug}/${_id}/${pageNumber}/${pageSize}`,
      filters,
      { ngProgress: ngProgress }
    );
  },

  getCollections(_id, ngProgress = false) {
    return Vue.axios.get(`collection/list/${_id}`, { ngProgress: ngProgress });
  },

  getCollectionGroups(_id, of, ngProgress = false) {
    return Vue.axios.get(`collection/list/groups/${of}/${_id}`, {
      ngProgress: ngProgress,
    });
  },

  //webstore
  getWebstore(_id, ngProgress = false) {
    return Vue.axios.get(`webstore/${_id}`, { ngProgress: ngProgress });
  },

  getAbout(_id, ngProgress = false) {
    return Vue.axios.get(`about/list/${_id}`, { ngProgress: ngProgress });
  },

  getContact(_id, ngProgress = false) {
    return Vue.axios.get(`contact/list/${_id}`, { ngProgress: ngProgress });
  },

  getPolicy(_id, policy, ngProgress = false) {
    return Vue.axios.get(`policy/list/${_id}/${policy}`, {
      ngProgress: ngProgress,
    });
  },

  getDeal(_id, dealId, ngProgress = false) {
    return Vue.axios.get(`deal/${_id}/${dealId}`, { ngProgress: ngProgress });
  },

  getDeals(_id, ngProgress = false) {
    return Vue.axios.get(`deal/list/active/${_id}`, { ngProgress: ngProgress });
  },

  verifyPayment(_id, object, ngProgress = false) {
    return Vue.axios.post(`payment/razorpay/verify/${_id}`, object, {
      ngProgress: ngProgress,
    });
  },
};
